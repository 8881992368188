import { getAuthState } from '@sparky/framework';
import { mfeProperty } from '../shared/AppAccelContext';
import { sparkyHasLicense } from '../shared/licensing';

export function checkSDWANisRunning() {
    const authState = getAuthState();
    const instances = authState?.instances;
    const sdwanInstance = instances?.get('cgx');
    const isSDWANRunning = sdwanInstance?.status === 'running';
    return isSDWANRunning;
}

const MFE_PROPERTY_STORE_ENABLED_FLAG = 'enable_app_acceleration';

export function checkSDWANLicense() {
    const authState = getAuthState();
    const SDWANMFEProperties = authState?.properties?.get('sdwan.features');
    const SDWANAppAccelLicense = SDWANMFEProperties?.features?.find(
        (mfe: mfeProperty | undefined) => mfe?.name === MFE_PROPERTY_STORE_ENABLED_FLAG
    )?.enabled;
    return SDWANAppAccelLicense;
}

export const LICENSE_TYPES = {
    APP_ACCEL: 'APP_ACCEL',
    SDWAN_APP_ACCEL: 'SDWAN_APP_ACCEL',
} as const;

export function getLicenseType() {
    try {
        const isSDWANRunning = checkSDWANisRunning();
        const tenantHasAppAcclLicense = sparkyHasLicense();
        const SDWANAppAccelLicense = checkSDWANLicense();

        if (isSDWANRunning && SDWANAppAccelLicense) {
            return LICENSE_TYPES.SDWAN_APP_ACCEL;
        } else if (tenantHasAppAcclLicense) {
            return LICENSE_TYPES.APP_ACCEL;
        }

        return undefined;
    } catch (e) {
        console.error("🚀 ~ getLicenseType ~ e:", e);
        return undefined;
    }
}

export const exposesObject: Object = {
    default: {
        CertDropDownWrapper: () => import('../shared/CertDropdown'),
        AppAccelerationToggleWrapper: () => import('../app/AppAccelerationToggleWrapper'),
        AppAccelerationTableWrapper: () => import('../app/AppAccelerationTableWrapper'),
    },
};
