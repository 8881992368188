export const makeQueryParams = (url: string, params: Record<string, unknown> | string): string => {
    let paramStr = '';
    
    if (typeof params === 'object') {
        const paramKeys = Object.keys(params);
        for (let i = 0, len = paramKeys.length; i < len; i++) {
            if (paramStr.length > 0) {
            paramStr += '&';
            }
            const key = paramKeys[i];
            paramStr += `${key}=${params[key]}`;
        }
    } else if (typeof params === 'string') {
        paramStr = params;
    }

    return `${url}?${paramStr}`;
}

const v2RootPath = '/workflows';
export const v3RootPath = '/configuration';

export const nav = {
    isV3Nav: false,
    rootPath: v2RootPath as typeof v2RootPath | typeof v3RootPath,
};

const urls = {
    naaHome: `${nav.rootPath}/app-acceleration`,
    sparkyCertManagement: '/manage/objects/certificate-management?container=prisma-access',

    // naaAppTechInfo is a dup of naaTechInfo.  However that may change which is why I am keeping both.
    naaInsightLink: '/dashboards/sase-health',
    naaAppTechInfo: 'https://docs.paloaltonetworks.com/prisma-access/administration/app-acceleration-in-prisma-access',
    naaTechInfo:    'https://docs.paloaltonetworks.com/prisma-access/administration/app-acceleration-in-prisma-access',

    naaUrlModifierCommonUI: '/sse/app_accl/',
    naaUrlModifier: '/sse/app_accl/',
    naaUrlModifierStg1: '/sse/app_accl/stg1/',
    naaUrlModifierStg2: '/sse/app_accl/stg2/',
    
    serverNaaSettingsCommonUI: 'v2/settings',
    serverNaaSettings: 'v1/settings',
    serverNaaGetCapabilities: 'v1/capabilities',
    serverNaaGetTenantAddonLicenses: 'v1/license_addons',
    serverGetAdemLicense: (tid: string) => {return `/api/v3/t/${tid}`; },
    serverAdemAACLInfo: (subTid: string) => { return `/api/v3/st/${subTid}/application-acceleration`; },
    serverFawkesCertInfo: (folder: string) => { 
        return `/api/config/v9.2/Device/CertificateManagement/CertificatesInfo?type=container&folder=${folder}&isBpa=no`; },
};

const proxyHandler = {
    get: (target: typeof urls, property: string | symbol) => {
      if (nav.isV3Nav && property in target && typeof property === 'string') {
        const value = target[property as keyof typeof urls];
        if (typeof value === 'string' && value?.includes(v2RootPath)) {
            return value.replace(v2RootPath, v3RootPath);
        }
        return value;
      }
      return target[property as keyof typeof urls];
    },
  };
  
const NaaURLs = new Proxy(urls, proxyHandler);

export default NaaURLs;


