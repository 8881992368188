import { exposesObject } from './scm-nav-common';
import { getLicenseType, LICENSE_TYPES } from './scm-nav-common';

export const phase1Nav: Object = {
    navigation: [
        {
            key: 'appAccelWorkflows',
            title: 'Workflows',
            expandable: true,
            path: '/workflows',
            noRBACheck: true,
            exact: false,
            mergeNav: true,
            children: [
                {
                    key: 'app-accel-config',
                    title: 'App Acceleration',
                    path: '/workflows/app-acceleration',
                    noRBACheck: true,
                    contentClassName: 'panwds-tw3',
                    component: () => import('../AppAccelRoutes'),
                    children: [
                        {
                            key: 'app-accel-config1',
                            title: 'Configuration',
                            path: '/workflows/app-acceleration',
                            noRBACheck: true,
                        },
                    ],
                },
            ],
        },
    ],
    exposes: exposesObject,
};

export const phase1SDWANNav: Object = {
    navigation: [],
    exposes: exposesObject
};

const NAV2_OBJECTS = {
    [LICENSE_TYPES.APP_ACCEL]: phase1Nav,
    [LICENSE_TYPES.SDWAN_APP_ACCEL]: phase1SDWANNav,
} as const;

export function getScmNav2Object() {
    const licenseType = getLicenseType();

    if (licenseType) {
        return NAV2_OBJECTS[licenseType];
    }
    return undefined;
}

