import {
    SPARKY_NAV3_LEFT_NAV_EXTPOINT,
    SPARKY_NAV3_ROUTES_EXTPOINT,
    SPARKY_NAV3_TSG_EXPOSES_COMPAT_EXTPOINT,
    SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
    SparkyLeftNavConfig,
    SparkyNavRouteConfig,
} from "@sparky/framework/extpoints";

import { ExtensionsConfig, StoreState, SparkyFramework } from "@sparky/framework";
import { nav, v3RootPath } from "../shared/urls";
import {
    exposesObject,
    getLicenseType,
    LICENSE_TYPES,
    checkSDWANisRunning,
} from "./scm-nav-common";

export const extensions = ({ sparkyFramework }: { sparkyFramework: SparkyFramework }) => {
    const stateExt = {
        // declare ext for api calls (please avoid, and using MFE Propery instead)
        point: SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
        configs: [
            {
                id: "netsec.app-accel-config.tsg.state.extension",
                config: {
                    name: "app-accwel-config",
                    requireFetch: true, // true for use the fetch function
                    loader: async () => {
                        const authState = sparkyFramework.getAuthState();
                        const instances = authState?.instances;
                        const sdwanInstance = instances?.get("cgx");
                        const isSDWANRunning = sdwanInstance?.status === "running";

                        const licenseType = getLicenseType();
                        return { licenseType, isSDWANRunning };
                    },
                },
            },
        ],
    };

    const nav3LeftNavExt: ExtensionsConfig<SparkyLeftNavConfig> = {
        point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
        configs: [
            {
                id: "sparky.nav.left.app-accel-config",
                config: {
                    stateLoaders: ["auth", "app-accel-config"],
                    navigation: () =>
                        getLicenseType() === LICENSE_TYPES.APP_ACCEL &&
                            !sparkyFramework.getState()?.["app-accel-config"]?.isSDWANRunning()
                            ? [
                                {
                                    key: "configuration",
                                    title: "Configuration",
                                    path: "/configuration",
                                    contentClassName: "panwds-tw3",
                                    children: [
                                        {
                                            key: "app-accel-config",
                                            title: "App Acceleration",
                                            path: "/configuration/app-acceleration",
                                            replacePaths: "/workflows/app-acceleration",
                                        },
                                    ],
                                    avail: () => {
                                        return (
                                            getLicenseType() === LICENSE_TYPES.APP_ACCEL &&
                                            !sparkyFramework
                                                .getState()
                                                ?.["app-accel-config"]?.isSDWANRunning()
                                        );
                                    },
                                },
                            ]
                            : [],
                },
            },
        ],
    };

    const nav3routeExt: ExtensionsConfig<SparkyNavRouteConfig> = {
        point: SPARKY_NAV3_ROUTES_EXTPOINT,
        configs: [
            {
                id: "sparky.nav.routes.app-acceleration",
                config: {
                    path: "/configuration/app-acceleration",
                    replacePaths: "/workflows/app-acceleration",
                    component: () => import("../AppAccelRoutes"),
                    contentClassName: "panwds-tw3",
                    isAllowed: () =>
                        getLicenseType() === LICENSE_TYPES.APP_ACCEL &&
                        !checkSDWANisRunning(),
                    mapStateToProps: (state: StoreState) => {
                        nav.isV3Nav = true;
                        nav.rootPath = v3RootPath;

                        return {
                            tsgId: state.auth.tsg_id,
                        };
                    },
                },
            },
        ],
    };

    const nav3exposesExt = {
        point: SPARKY_NAV3_TSG_EXPOSES_COMPAT_EXTPOINT,
        configs: [
            {
                id: "netsec.app-accel-config.exposes",
                config: {
                    exposes: () => exposesObject,
                },
            },
        ],
    };
    return [nav3LeftNavExt, nav3routeExt, nav3exposesExt, stateExt];
};
