import { extensions } from './routes/scm-nav-v3';
import { getScmNav2Object } from './routes/scm-nav-v2';

const init = () => {
    return async function tsgLoad() {
        return getScmNav2Object();
    };
}

init.extensions = extensions;

export default init;
